<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '650px' }"
    header="Vehículo Detalle"
    :modal="true"
    :maximizable="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div
          class="field col-12 md:col-4"
          v-if="
            !data_vehiculo.nombreCliente || data_vehiculo.nombreCliente == ''
          "
        >
          <span
            ><strong>SELECCIONE CLIENTE: </strong
            ><span class="p-invalid">*</span></span
          >
          <div class="p-inputgroup">
            <Dropdown
              v-model="clienteSelected"
              :options="clientes"
              optionLabel="nombre"
              placeholder="Seleccione un cliente..."
              required="true"
              optionValue="id"
              :filter="true"
            />
            <Button
              icon="pi pi-user"
              class="p-button-info"
              v-tooltip.top="'Nuevo Cliente'"
              @click="activarCliente"
            />
          </div>
          <small class="p-invalid" v-if="errors.cliente">{{
            errors.cliente[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span><strong>TAMAÑO: </strong><span class="p-invalid">*</span></span>
          <Dropdown
            v-model="tamanioSelected"
            :options="tamanios"
            optionLabel="label"
            placeholder="Seleccione un tamaño..."
            optionValue="value"
          />
          <small class="p-invalid" v-if="errors.tamanio">{{
            errors.tamanio[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span><strong>PLACA: </strong><span class="p-invalid">*</span></span>
          <Textarea
            v-model.trim="data_vehiculo.placa"
            placeholder="Placa"
            required="true"
            rows="1"
            autoResize
          />
          <small class="p-invalid" v-if="errors.placa">{{
            errors.placa[0]
          }}</small>
        </div>
        <div
          :class="
            data_vehiculo.id ? 'field col-12 md:col-3' : 'field col-12 md:col-4'
          "
        >
          <span><strong>MARCA: </strong><span class="p-invalid">*</span></span>
          <div class="p-inputgroup">
            <Dropdown
              v-model="marcaSelected"
              :options="marcas"
              optionLabel="nombre"
              placeholder="Seleccione una marca..."
              optionValue="id"
              :filter="true"
              @change="filtrarModelos()"
            />
            <Button
              icon="pi pi-plus"
              class="p-button-info"
              v-tooltip.top="'Nueva Marca'"
              @click="activarMarca"
            />
          </div>
          <small class="p-invalid" v-if="errors.marca">{{
            errors.marca[0]
          }}</small>
        </div>
        <div
          :class="
            data_vehiculo.id ? 'field col-12 md:col-3' : 'field col-12 md:col-4'
          "
        >
          <span><strong>MODELO: </strong><span class="p-invalid">*</span></span>
          <div class="p-inputgroup">
            <Dropdown
              v-model="modeloSelected"
              :options="modelosFiltrados"
              optionLabel="nombre"
              placeholder="Seleccione un modelo..."
              optionValue="id"
              :filter="true"
            />
            <Button
              icon="pi pi-plus"
              class="p-button-info"
              v-tooltip.top="'Nuevo Modelo'"
              @click="activarModelo"
            />
          </div>
          <small class="p-invalid" v-if="errors.modelo">{{
            errors.modelo[0]
          }}</small>
        </div>
        <div
          :class="
            data_vehiculo.id ? 'field col-12 md:col-3' : 'field col-12 md:col-4'
          "
        >
          <span><strong>COLOR: </strong><span class="p-invalid">*</span></span>
          <Textarea
            v-model.trim="data_vehiculo.color"
            placeholder="Color"
            rows="1"
            autoResize
          />
          <small class="p-invalid" v-if="errors.color">{{
            errors.color[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3" v-if="data_vehiculo.id">
          <span><strong>ESTADO: </strong><span class="p-invalid">*</span></span>
          <Dropdown
            v-model="data_vehiculo.estado"
            :disabled="!data_vehiculo.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <span class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></span
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        :disabled="!this.clienteSelected"
        :label="data_vehiculo.id ? 'ACTUALIZAR' : 'GUARDAR'"
        icon="pi pi-save"
        class="p-button-info p-button-lg"
        :loading="enviado"
        @click="saveVehiculo"
      />
    </template>
  </Dialog>
  <ClienteCreate
    :show="clienteModal"
    :cliente="cliente"
    @closeModal="cerrarModalCliente"
    @actualizarListado="cargarClientesListado"
  ></ClienteCreate>
  <MarcaCreate
    :show="marcaModal"
    :marca="marca"
    @closeModal="cerrarModalMarca"
    @actualizarListadoMarca="getMarcaListado"
  ></MarcaCreate>
  <ModeloCreate
    :show="modeloModal"
    :modelo="modelo"
    :marcas="marcas"
    @closeModal="cerrarModalModelo"
    @actualizarListadoModelo="getModeloListado"
  ></ModeloCreate>
</template>

<script>
import VehiculoService from "@/service/VehiculoService";
import ClienteService from "@/service/ClienteService";
import ClienteCreate from "@/module/clientes/ClienteCreate.vue";
import MarcaCreate from "@/module/vehiculos/MarcaCreate.vue";
import ModeloCreate from "@/module/vehiculos/ModeloCreate.vue";

export default {
  components: {
    ClienteCreate,
    MarcaCreate,
    ModeloCreate,
  },
  emits: ["closeModalVehiculo", "actualizarListadoVehiculo"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    vehiculo: {
      type: Object,
      default() {
        return {};
      },
    },
    nombreCliente: {
      type: String,
      default: "",
    },
  },
  vehiculoService: null,
  clienteService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_vehiculo: this.vehiculo,
      enviado: false,
      estados: [
        { label: "ACTIVO", value: 1 },
        { label: "INACTIVO", value: 0 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      tamanios: [
        { label: "PEQUEÑO", value: 1 },
        { label: "MEDIANO", value: 2 },
        { label: "GRANDE", value: 3 },
        { label: "OTRO", value: 4 },
      ],
      tamanioSelected: 1,
      clienteSelected: 1,
      clienteModal: false,
      cliente: { estado: { label: "Activo", value: 1 } },
      clientes: [],
      marcas: [],
      modelos: [],
      modelosFiltrados: [],
      marcaModal: false,
      marca: {},
      modeloModal: false,
      modelo: {},
      marcaSelected: null,
      modeloSelected: null,
    };
  },
  created() {
    this.vehiculoService = new VehiculoService();
    this.clienteService = new ClienteService();
  },
  mounted() {
    this.datos_necesarios_vehiculo();
  },
  methods: {
    getMarcaListado(marca = null) {
      this.vehiculoService.getMarcasAll().then((response) => {
        if (marca) {
          this.marcaSelected = marca.id;
        }
        this.marcas = response.marcas ?? [];
      });
    },
    cerrarModalMarca() {
      this.marcaModal = false;
      this.marca = {};
    },
    activarMarca() {
      this.marcaModal = true;
      this.marca = { estado: { label: "Activo", value: 1 } };
    },

    getModeloListado(selected = null) {
      this.vehiculoService.getModelosAll().then((response) => {
        if (selected) {
          this.modeloSelected = selected.id;
        }
        this.modelos = response.modelos ?? [];
        this.filtrarModelos();
      });
    },

    cerrarModalModelo() {
      this.modeloModal = false;
      this.modelo = {};
    },
    activarModelo() {
      this.modeloModal = true;
      this.modelo = { estado: { label: "Activo", value: 1 } };
      this.modelo.marca_id = this.marcaSelected ?? null;
    },

    filtrarModelos() {
      this.modelosFiltrados = this.modelos.filter(
        (modelo) => modelo.marca_id == this.marcaSelected
      );
    },

    datos_necesarios_vehiculo() {
      this.vehiculoService
        .getVehiculoDatosNecesarios()
        .then((response) => {
          this.marcas = response.marcas ?? [];
          this.modelos = response.modelos ?? [];
          this.clientes = response.clientes ?? [];
        })
        .catch((error) => console.log(error));
    },

    cargarClientesListado(selected = null) {
      this.clienteService.getClientesAll().then((response) => {
        if (selected) {
          this.clienteSelected = selected.id;
        }
        this.clientes = response ?? [];
      });
    },
    cerrarModalCliente() {
      this.clienteModal = false;
      this.cliente = {};
    },
    activarCliente() {
      this.clienteModal = true;
      this.cliente = {
        estado: { label: "Activo", value: 1 },
        //nombre: this.clienteSelect ?? [],
      };
    },
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModalVehiculo");
      this.enviado = false;
    },
    validacionesForm() {
      if (!this.data_vehiculo.placa) {
        this.errors.placa = ["Placa es requerido"];
      }
      if (!this.marcaSelected) {
        this.errors.marca = ["Marca es requerido"];
      }
      if (!this.modeloSelected) {
        this.errors.modelo = ["Modelo es requerido"];
      }
      if (!this.data_vehiculo.color) {
        this.errors.color = ["Color es requerido"];
      }
      if (!this.clienteSelected) {
        this.errors.cliente = ["Cliente es requerido"];
      }
      if (!this.tamanioSelected) {
        this.errors.tamanio = ["Tamaño es requerido"];
      }
      return Object.keys(this.errors).length === 0;
    },
    saveVehiculo() {
      this.errors = {};
      this.enviado = true;
      if (!this.validacionesForm()) {
        this.enviado = false;
        return;
      }
      //editar vehiculo
      if (this.vehiculo.id) {
        let vehiculo_enviar = {
          ...this.data_vehiculo,
          estado: this.data_vehiculo.estado.value,
          cliente_id: this.clienteSelected,
          tamanioSelected: this.tamanioSelected,
          marca: this.marcaSelected,
          modelo: this.modeloSelected,
        };
        this.vehiculoService.updateVehiculo(vehiculo_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListadoVehiculo");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      } else {
        //CREANDO UN NUEVO PROVEEDOR
        let tmp = this.data_vehiculo;
        this.data_vehiculo = {
          ...this.data_vehiculo,
          cliente_id: this.clienteSelected,
          tamanioSelected: this.tamanioSelected,
          estado: 1,
          marca: this.marcaSelected,
          modelo: this.modeloSelected,
        };
        this.vehiculoService
          .sendVehiculoNew(this.data_vehiculo)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
              this.data_vehiculo = tmp;
            } else {
              this.errors = {};
              if (data.status == 400) {
                this.$toast.add({
                  severity: "error",
                  summary: "Error!",
                  detail: data.mensaje,
                  life: 10000,
                });
                return;
              } else {
                this.$toast.add({
                  severity: "success",
                  summary: "Exito!",
                  detail: data.mensaje,
                  life: 10000,
                });
                this.$emit("actualizarListadoVehiculo", data.vehiculo);
                this.ocultarDialog();
              }
            }
            this.enviado = false;
          });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    vehiculo(val) {
      this.data_vehiculo = val;
      if (val.id) {
        this.clienteSelected = val.cliente_id;
        this.marcaSelected = parseFloat(val.marca);
        this.modeloSelected = parseFloat(val.modelo);
        this.tamanioSelected = parseFloat(val.tamanio);
        this.filtrarModelos();
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
