<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '350px' }"
    header="Marca Detalle"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <span
            ><strong>NOMBRE DE MARCA: </strong>
            <span class="p-invalid">*</span></span
          >
          <InputText
            v-model.trim="data_marca.nombre"
            required="true"
            autofocus
          />
          <small class="p-invalid" v-if="errors.nombre">{{
            errors.nombre[0]
          }}</small>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <span class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></span
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="guardarMarca"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import VehiculoService from "@/service/VehiculoService";
export default {
  emits: ["closeModal", "actualizarListadoMarca"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    marca: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  vehiculoService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_marca: this.marca,
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  created() {
    this.vehiculoService = new VehiculoService();
  },
  methods: {
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.enviado = false;
    },
    guardarMarca() {
      this.errors = {};
      this.enviado = true;
      if (this.marca.id) {
        let marcaSend = {
          ...this.data_marca,
          estado: this.data_marca.estado.value,
        };
        this.vehiculoService.updateMarca(marcaSend).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListadoMarca");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      } else {
        let tmp = this.data_marca;
        this.data_marca = {
          ...this.data_marca,
          estado: this.data_marca.estado.value,
        };
        this.vehiculoService.saveMarca(this.data_marca).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
            this.data_marca = tmp;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 10000,
            });
            this.$emit("actualizarListadoMarca", data.marca);
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    marca(val) {
      this.data_marca = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
