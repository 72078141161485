<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '400px' }"
    header="Modelo Detalle"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6">
          <label for="modelo"
            ><strong>MARCA: </strong> <span class="p-invalid">*</span></label
          >
          <Dropdown
            v-model="data_modelo.marca_id"
            :options="marcas"
            optionLabel="nombre"
            optionValue="id"
            placeholder="Seleccione Marca"
            required="true"
            :filter="true"
          />
          <small class="p-invalid" v-if="errors.modelo">{{
            errors.modelo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6">
          <label for="nombre"
            ><strong>MODELO: </strong> <span class="p-invalid">*</span></label
          >
          <InputText
            id="nombre"
            v-model.trim="data_modelo.nombre"
            required="true"
            autofocus
          />
          <small class="p-invalid" v-if="errors.nombre">{{
            errors.nombre[0]
          }}</small>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="guardarModelo"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import VehiculoService from "@/service/VehiculoService";
export default {
  emits: ["closeModal", "actualizarListadoModelo"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    modelo: {
      type: Object,
      default() {
        return {};
      },
    },
    marcas: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  vehiculoService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_modelo: this.modelo,
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  created() {
    this.vehiculoService = new VehiculoService();
  },
  methods: {
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.enviado = false;
    },
    guardarModelo() {
      this.errors = {};
      this.enviado = true;
      if (this.modelo.id) {
        let marcaSend = {
          ...this.data_modelo,
          estado: this.data_modelo.estado.value,
          marca_id: this.data_modelo.marca_id,
        };
        this.vehiculoService.updateModelo(marcaSend).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListadoModelo");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      } else {
        let tmp = this.data_modelo;
        this.data_modelo = {
          ...this.data_modelo,
          estado: this.data_modelo.estado.value,
          marca_id: this.data_modelo.marca_id,
        };
        this.vehiculoService.saveModelo(this.data_modelo).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
            this.data_modelo = tmp;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 10000,
            });
            this.$emit("actualizarListadoModelo", data.modelo);
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    modelo(val) {
      this.data_modelo = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
