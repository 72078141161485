import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class VehiculoService {
  async getVehiculosAll() {
    const vehiculos = await fetchWrapper.get(`${ruta}/vehiculos`);
    return vehiculos;
  }

  async sendVehiculoNew(datos) {
    const newVehiculo = await fetchWrapper.post(`${ruta}/vehiculos`, datos);
    return newVehiculo;
  }

  async updateVehiculo(datos) {
    const updateVehiculo = await fetchWrapper.put(
      `${ruta}/vehiculos/` + datos.id,
      datos
    );
    return updateVehiculo;
  }

  async deleteVehiculo(id) {
    return await fetchWrapper.delete(`${ruta}/vehiculos/${id}`);
  }

  async getVehiculoDatosNecesarios() {
    const datosNecesarios = await fetchWrapper.post(
      `${ruta}/vehiculos/datosNecesarios`
    );
    return datosNecesarios;
  }

  async getMarcasAll() {
    const marcas = await fetchWrapper.get(`${ruta}/marcas`);
    return marcas;
  }

  async getModelosAll() {
    const modelos = await fetchWrapper.get(`${ruta}/modelos`);
    return modelos;
  }

  async saveMarca(datos) {
    const newMarca = await fetchWrapper.post(`${ruta}/marcas`, datos);
    return newMarca;
  }

  async updateMarca(datos) {
    const updateMarca = await fetchWrapper.put(
      `${ruta}/marcas/` + datos.id,
      datos
    );
    return updateMarca;
  }

  async saveModelo(datos) {
    const newModelo = await fetchWrapper.post(`${ruta}/modelos`, datos);
    return newModelo;
  }

  async updateModelo(datos) {
    const updateModelo = await fetchWrapper.put(
      `${ruta}/modelos/` + datos.id,
      datos
    );
    return updateModelo;
  }
}
